@import url("https://fonts.googleapis.com/css2?family=Rochester&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  .tap-none {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}

html {
  scroll-behavior: smooth;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
